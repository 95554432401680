<template>
  <div class="system-advertising-container">
    <div class="system-advertising-title">{{ translateTitle('系统广告') }}</div>
    <div class="system-advertising-info">
      {{ translateTitle('开屏、资讯列表、主题列表为系统支持的广告配置位。') }}
      <!-- <a href="http://zhitu.isux.us/" target="_blank">http://zhitu.isux.us/</a> -->
      <!-- <a
        v-if="config.hidden_mag_version != 1"
        :href="magSample.community_operation_spread.url"
        target="_blank"
      >
        {{ translateTitle('查看示例') }}
      </a> -->
    </div>
    <div v-loading="loading" class="system-advertising-list">
      <div
        v-for="(item, index) in sliderList"
        :key="index"
        class="system-advertising-item"
        @click="handleClickList(item)"
      >
        <div class="system-advertising-item-image">
          <el-image :src="item.image" />
        </div>
        <div class="system-advertising-item-title">
          {{ translateTitle(item.name) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { translateTitle } from '@/utils/i18n'
  import { mapGetters } from 'vuex'
  import magSample from '@/config/mag.sample'
  import config from './config.js'
  export default {
    data() {
      return {
        loading: false,
        sliderItemList: [],
        magSample: magSample,
        sliderList: config.sliderList,
      }
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
    },
    methods: {
      translateTitle,
      handleClickList(item) {
        this.$router.push({
          path: `/operate/${item.path}`,
          query: {
            place: item.place,
            title: item.name,
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.system-advertising';
  #{$base}-container {
    #{$base}-title {
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: bold;
    }
    #{$base}-info {
      font-size: 13px;
      color: $base-color-text-secondary;
    }
    #{$base}-list {
      display: flex;
      min-height: 100px;
      margin-top: 20px;
      #{$base}-item {
        margin-right: 50px;
        cursor: pointer;
        &-image {
          display: table-cell;
          width: 210px;
          height: 82px;
          text-align: center;
          vertical-align: middle;
          background-color: #f5f7f5;
        }
        &-title {
          display: block;
          width: 100%;
          margin-top: 8px;
          overflow: hidden;
          line-height: 20px;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &-desc {
          margin-top: 6px;
          font-size: 13px;
          line-height: 16px;
          color: $base-color-text-secondary;
          text-align: center;
        }
      }
    }
  }
</style>
