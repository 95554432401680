var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "system-advertising-container" }, [
    _c("div", { staticClass: "system-advertising-title" }, [
      _vm._v(_vm._s(_vm.translateTitle("系统广告"))),
    ]),
    _c("div", { staticClass: "system-advertising-info" }, [
      _vm._v(
        " " +
          _vm._s(
            _vm.translateTitle(
              "开屏、资讯列表、主题列表为系统支持的广告配置位。"
            )
          ) +
          " "
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "system-advertising-list",
      },
      _vm._l(_vm.sliderList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "system-advertising-item",
            on: {
              click: function ($event) {
                return _vm.handleClickList(item)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "system-advertising-item-image" },
              [_c("el-image", { attrs: { src: item.image } })],
              1
            ),
            _c("div", { staticClass: "system-advertising-item-title" }, [
              _vm._v(" " + _vm._s(_vm.translateTitle(item.name)) + " "),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }