const magSample = {
  community_createwshare: {
    name: '创建微分享',
    url: 'http://help_for_magapp.mydoc.io/?t=181014/',
  },
  community_createpunchcard: {
    name: '创建打卡圈',
    url: 'http://help_for_magapp.mydoc.io/?t=181015/',
  },
  community_forum_config: {
    name: '论坛圈配置',
    url: 'http://help_for_magapp.mydoc.io/?t=181017/',
  },
  community_wshare_config: {
    name: '轻分享圈配置',
    url: 'http://help_for_magapp.mydoc.io/?t=181019/',
  },
  community_punchcard_config: {
    name: '打卡圈配置',
    url: 'http://help_for_magapp.mydoc.io/?t=181022/',
  },
  community_operation_spread: {
    name: '广告中心',
    url: 'http://help_for_magapp.mydoc.io/?t=181020/',
  },
  community_operation_spread_post: {
    name: '新增广告',
    url: 'http://help_for_magapp.mydoc.io/?t=173186/',
  },
  config_app_jump: {
    name: 'APP跳转配置',
    url: 'http://help_for_magapp.mydoc.io/?t=177452/',
  },
  config_index_operation: {
    name: '首页运营位配置',
    url: 'http://help_for_magapp.mydoc.io/?t=173118/',
  },
  config_slider_simple: {
    name: '图片轮播组件示例',
    url: 'http://lxh.magcloud.cc/',
  },
  config_naviicons_simple: {
    name: '图标导航组件示例',
    url: 'http://help_for_magapp.mydoc.io/?t=175189/',
  },
  config_component_des_simple: {
    name: '组件与上一组件之间的结构关系详情说明',
    url: 'http://help_for_magapp.mydoc.io/?t=175197/',
  },
  config_scrollnew_simple: {
    name: '滚动公告组件示例',
    url: 'http://help_for_magapp.mydoc.io/?t=175214/',
  },
  config_scrollnew_simple_download: {
    name: '滚动公告资源下载',
    url: 'https://pan.baidu.com/s/1i4W9LGx/',
  },
  config_scrollnew_simple_build: {
    name: '滚动公告建立公告专栏',
    url: '#!/info',
  },
  config_index_navitext: {
    name: '文本导航查看示例',
    url: 'http://help_for_magapp.mydoc.io/?t=175206/',
  },
  config_index_navipicscroll: {
    name: '图片导航可滚动查看示例',
    url: 'http://help_for_magapp.mydoc.io/?t=175201/',
  },
  config_index_navipicfixed: {
    name: '图片导航不可滚动查看示例',
    url: 'http://help_for_magapp.mydoc.io/?t=175226/',
  },
  config_index_picset: {
    name: '图片组合查看示例',
    url: 'http://help_for_magapp.mydoc.io/?t=175254/',
  },
  config_find: {
    name: '发现页配置',
    url: 'http://help_for_magapp.mydoc.io/?t=181011/',
  },
  config_circle: {
    name: '圈子配置',
    url: 'http://help_for_magapp.mydoc.io/?t=181013/',
  },
}

export default magSample
